<template>
  <div class="page-login-desktop">
    <Table
        label="پیام های ارسالی"
        icon="mdi-message-bulleted"
        :table="table">
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">{{ item.user }}</td>
        <td class="text-center">{{ item.title }}</td>
        <td class="text-center">
          <v-chip :color="item.status.color" text-color="white">
            {{ item.status.label }}
          </v-chip>
        </td>
        <td class="text-center">


          <ViewButton
              @click="item.actions.view"
          />

          <MessageButton
              @click="item.actions.replay"
          />


        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.requestPagenumber"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page+1})}"
    />


    <v-dialog v-model="viewMessage.modal" width="500">
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-message-bulleted
          </v-icon>
          مشاهده جزییات پیام
        </v-card-title>
        <v-card-text>
          {{ viewMessage.body }}
          <br>
          <br>
          {{ viewMessage.created_at.split(" ").join(" - ") }}
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="viewMessage.modal=false"
              large text color="main">
            بستن
          </v-btn>

        </v-card-actions>
      </v-card>

    </v-dialog>

    <NewMessage
        @onSubmit="fetchItems"
        :messageData="newMessage"
    />

  </div>
</template>

<script>

import Table from "@/components/Table"
import MessageButton from "@/components/MessageButton"
import ViewButton from "@/components/ViewButton"
import NewMessage from "@/components/NewMessage"
import Pagination from "@/components/Pagination"
import {destroy, messageSent, show,} from "@Newfiling/module-message/src/api";
import {strLimit} from "@Newfiling/Services";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination,
    NewMessage,
    ViewButton,
    MessageButton,
  },

  data() {
    return {
      filters: {
        take: 10,
        skip: 0
      },

      table: {
        headers: [
          '#',
          'تاریخ ثبت',
          'کاربر',
          'عنوان',
          'وضعیت',
          'عملیات',
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },

      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      viewMessage: {
        modal: false,
        body: "",
        created_at: "",
      },

      newMessage: {
        modal: false,
        phoneNumber: null,
      }
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'پیام های ارسال نیوفایل',
        disabled: true,
        href: '/panel/messages/sent',
      },
    ])
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.table.loading = true;
      try {
        const res = (await messageSent(this.filters))?.data || {
          message: [],
          recordcount: 0,
        };

        console.log({res})
        this.table.items = res?.message.map(item => {
          return {
            created_at: item.solarDateofRegistration.split(" ").join(" - "),
            user: item.mobileRecipient,
            title: strLimit(item.title, 10),
            status: {
              color: item.messagestatus ? 'danger' : 'success',
              label: item.messagestatus ? 'ارسال شده' : 'ارسال نشده',
            },
            actions: {

              view: () => {
                this.viewMessage = {
                  ...this.viewMessage,
                  modal: true,
                  body: item.messagetext,
                  created_at: item.solarDateofRegistration,
                }
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                }
              },

              replay: () => {
                this.newMessage = {
                  modal: true,
                  phoneNumber: item.mobileRecipient,
                }
              },
            }
          }
        });
        this.table.totalCount = res.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await destroy(this.deleteItem.id);
        this.$toast.success('پیام مورد نظر با موفقیت حذف شد.');
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        }
        await this.fetchItems();
      } catch (e) {
        console.log(e)
      }
      this.deleteItem.loading = false;
    },
  }

}
</script>
